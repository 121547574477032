import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';

import { RootState } from 'store';
import { WEB_PAYMENT_SERVICES, WEB_ONE_TIME_PRODUCT_CODES, Currency, AVAILABLE_ONECLICK_METHODS } from 'constants/billing';
import { WebOneTimePurchases, WebPurchases, WebPurchase, PaymentMethods } from 'api/purchases/interfaces';

const getWebAllSubscriptions = (state: RootState) => state.billing.webAllSubscriptions;
const getWebOneTimePurchases = (state: RootState) => state.billing.webOneTimePurchases;
const getWebPaymentMethod = (state: RootState) => state.billing.webPaymentMethod;
const getOneClickEnableParam = (state: RootState) => state.remoteConfig.remoteConfigParams.isOneClickEnabled;

export const getCurrentWebPaymentServices = createSelector([getWebAllSubscriptions], (transactions: WebPurchases) => {
  return transactions.reduce((services: WEB_PAYMENT_SERVICES[], tr: any) => {
    if (tr.payment_service) {
      services.push(tr.payment_service);
    }
    return services;
  }, []);
});

export const getWebOneTimePurchaseCodes = createSelector(
  [getWebOneTimePurchases],
  (oneTimePurchases: WebOneTimePurchases): WEB_ONE_TIME_PRODUCT_CODES[] => {
    return oneTimePurchases.map(purchase => purchase.product_code as unknown as WEB_ONE_TIME_PRODUCT_CODES);
  },
);

export const getWebActiveSubscription = createSelector([getWebAllSubscriptions], (subscriptions: WebPurchases): WebPurchase | undefined => {
  return subscriptions.find(i => i!.active && !i!.canceled_at);
});

export const isSolidgatePaymentService = createSelector([getCurrentWebPaymentServices], paymentServices =>
  paymentServices.some((ps: WEB_PAYMENT_SERVICES) => [WEB_PAYMENT_SERVICES.SOLIDGATE].includes(ps)),
);

export const getEmailFromSubscriptions = createSelector([getWebAllSubscriptions], (subscriptions: WebPurchases) => {
  let currentSub = subscriptions.find(i => i!.active && !i!.canceled_at);

  if (_.isEmpty(currentSub)) {
    currentSub = subscriptions.sort((a, b) => moment(b?.expire_at).valueOf() - moment(a?.expire_at).valueOf())[0];
  }

  return currentSub?.email;
});

export const getPrevCurrency = createSelector(
  [getWebAllSubscriptions, getWebOneTimePurchases],
  (transactions: WebPurchases, oneTimePurchases: WebOneTimePurchases): Currency => {
    const currency = transactions?.[0]?.currency || oneTimePurchases?.[0]?.currency;

    return currency;
  },
);

export const getPrevProject = createSelector([getWebOneTimePurchases], (oneTimePurchases: WebOneTimePurchases) => {
  return oneTimePurchases?.[0]?.project;
});

export const checkOneClickAvailable = createSelector(
  [getWebPaymentMethod, getOneClickEnableParam],
  (paymentMethod: PaymentMethods | '', isEnabled: boolean) => {
    return isEnabled && paymentMethod && AVAILABLE_ONECLICK_METHODS.includes(paymentMethod);
  },
);
