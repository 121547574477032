import { Platform } from 'react-native';

import { PAYMENT_METHODS } from 'constants/billing';
import * as ROUTES from 'constants/routes';

import {
  PackType,
  RightTextColors,
  ViewType,
  AstrologerConsultationPacks,
  AstrologerPackSpecialOffer,
  AstrologerPackWelcomeOffer,
  AstrologerPacksFlows,
  AstrologerPackRoutes,
  PACK_SCREENS_IDS,
  ASTROLOGERS_TRIGGERS_TYPES,
  AstrologersMonetizationConfig,
} from './interfaces';

export const PACK_SCREENS_ROUTES: AstrologerPackRoutes = {
  [PACK_SCREENS_IDS.PACKS]: ROUTES.ADVISORS_PACKS,
  [PACK_SCREENS_IDS.WELCOME_OFFER]: ROUTES.ADVISORS_PACK_WELCOME_OFFER,
  [PACK_SCREENS_IDS.SPECIAL_OFFER]: ROUTES.ADVISORS_PACK_SPECIAL_OFFER,
};

export const astrologerPacksFlows: AstrologerPacksFlows = {
  noPurchased: [PACK_SCREENS_IDS.WELCOME_OFFER],
  purchased: [PACK_SCREENS_IDS.PACKS, PACK_SCREENS_IDS.SPECIAL_OFFER],
};

export const FORCE_TRIGGERS = [
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION,
  ASTROLOGERS_TRIGGERS_TYPES.ADVISORS_ONBOARDING,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN,
  ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP,
  ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL,
];

export const CHATS_TRIGGERS = [
  ASTROLOGERS_TRIGGERS_TYPES.BALANCE,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_TIME_UP,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION,
  ASTROLOGERS_TRIGGERS_TYPES.END_CHAT,
  ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP,
  ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL,
];

export const astrologersMonetizationConfig: AstrologersMonetizationConfig = {
  [ASTROLOGERS_TRIGGERS_TYPES.BALANCE]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.CHAT_TIME_UP]: false,
  [ASTROLOGERS_TRIGGERS_TYPES.END_CHAT]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL]: true,
};

export const astrologerConsultationPacks: AstrologerConsultationPacks = {
  title: '',
  subtitle: '',
  btnText: '',
  packType: PackType.FOUR,
  footnoteText: '',
  withCloseIcon: true,
  viewType: ViewType.SAVE,
  defaultProductId: 9,
  availablePaymentMethods: Platform.select({
    ios: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.PAYPAL],
    android: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.GOOGLE_PAY, PAYMENT_METHODS.PAYPAL],
  }) as PAYMENT_METHODS[],
  packs: [
    {
      title: '', // '3 \n min'
      minutesAmount: 3,
      activePrice: '$1.00',
      notActivePrice: '$2.00',
      freeMinsAmount: 0,
      saveAmount: '',
      currency: 'USD',
      price: 1,
      rightText: '{pricePerMin}/min',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      CAD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      AUD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
    },
    {
      title: '', // '9 \n min'
      minutesAmount: 9,
      activePrice: '$19.99',
      notActivePrice: '$29.99',
      freeMinsAmount: 3,
      saveAmount: '$10',
      currency: 'USD',
      price: 19.99,
      rightText: '{pricePerMin}/min',
      rightTextColor: RightTextColors.GREEN,
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      CAD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      AUD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
    },
    {
      title: '', // '30 \n min'
      minutesAmount: 30,
      activePrice: '$49.99',
      notActivePrice: '$99.99',
      freeMinsAmount: 15,
      saveAmount: '$50',
      currency: 'USD',
      price: 49.99,
      rightText: '{pricePerMin}/min',
      rightTextColor: RightTextColors.GREEN,
      rightSubText: '',
      badgeText: '_mostPopular',
      EUR: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      CAD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      AUD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
    },
    {
      title: '', // '60 \n min',
      minutesAmount: 60,
      activePrice: '$79.99',
      notActivePrice: '$198.99',
      freeMinsAmount: 36,
      saveAmount: '$119',
      currency: 'USD',
      price: 79.99,
      rightText: '{pricePerMin}/min',
      rightTextColor: RightTextColors.GREEN,
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      CAD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      AUD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
    },
  ],
};

export const astrologerPackSpecialOffer: AstrologerPackSpecialOffer = {
  availablePaymentMethods: Platform.select({
    ios: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.PAYPAL],
    android: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.GOOGLE_PAY, PAYMENT_METHODS.PAYPAL],
  }) as PAYMENT_METHODS[],
  pack: {
    title: '',
    minutesAmount: 10,
    activePrice: '$2.99',
    notActivePrice: '$9.99',
    freeMinsAmount: 0,
    saveAmount: '',
    currency: 'USD',
    price: 9.99,
    badgeText: '',
    EUR: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
    CAD: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
    AUD: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
  },
  title: '',
  subtitle: '',
  btnText: '',
  footnoteText: '',
};

export const astrologerPackWelcomeOffer: AstrologerPackWelcomeOffer = {
  availablePaymentMethods: Platform.select({
    ios: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.PAYPAL],
    android: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.GOOGLE_PAY, PAYMENT_METHODS.PAYPAL],
  }) as PAYMENT_METHODS[],
  pack: {
    title: '',
    minutesAmount: 10,
    activePrice: '$2.99',
    rightText: '',
    notActivePrice: '$19.99',
    freeMinsAmount: 0,
    saveAmount: '',
    currency: 'USD',
    price: 2.99,
    badgeText: '',
    EUR: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
    CAD: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
    AUD: {
      activePrice: '',
      notActivePrice: '',
      price: 1,
    },
  },
  title: '',
  percentsOff: 85,
  subtitle: '',
  btnText: '',
  footnoteText: '',
};
