import { Linking, Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { createOneClickPayment, getPaymentMethod } from 'api/purchases';
import { OneClickPaymentData, OneTimePaymentResponse } from 'api/purchases/interfaces';
import { SUB_CANCEL_LINK, WEB_SUB_CANCEL_LINK } from 'constants/general';

import { BILLING_TYPES } from './types';

const setWebPaymentMethod = createAction(BILLING_TYPES.SET_WEB_PAYMENT_METHOD);

export const trackEvent = (event: string, params?: any) => Analytics.trackEvent('billing', event, params);

export const cancelSubscription = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      billing: { webTransactions },
    } = getState();

    const webSubEmail = webTransactions?.find(sub => sub?.customer_email)?.customer_email || '';

    const idfm = webUUID ? webUUID : await DeviceProps.getIDFM();
    const link = webSubEmail ? `${WEB_SUB_CANCEL_LINK}${encodeURIComponent(webSubEmail)}&idfm=${idfm}&platform=${Platform.OS}` : SUB_CANCEL_LINK;
    Linking.openURL(link || '');
  };
};

export const fetchPaymentMethod = () => {
  return async (dispatch: AppDispatch) => {
    const paymentMethod = await getPaymentMethod();
    return dispatch(setWebPaymentMethod(paymentMethod));
  };
};

export const payByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: OneClickPaymentData;
  onSuccess?: (res: OneTimePaymentResponse['one_time_payment']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const response = await createOneClickPayment(data);

      if (typeof onSuccess === 'function') {
        onSuccess(response.one_time_payment);
      }

      return response;
    } catch (error) {
      console.log('[ERROR PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};
