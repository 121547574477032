import { createAction } from 'redux-actions';
import { isEmpty, shuffle } from 'lodash';

import Analytics from 'analytics';
import { addPromocode, getAstrologersProfiles } from 'api/astrology-chat';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { getRandomTexts } from 'utils/strings';
import { t } from 'localization';
import { selectZodiacSign } from 'store/profile/selectors';
import { setUserParams } from 'store/profile/actions';
import { sendDataToAstrologers as sendData } from 'api/astrologers';
import { getWebData } from 'store/palm-reading-daily/actions';
import { selectLeftHandData } from 'store/palm-reading-daily/selectors';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';
import getAstrologistReviews from 'api/astrology-chat/get-reviews';
import { Astrologist } from 'api/astrology-chat/constants';

import { countUnreadMessages, getLastMessages } from '../chat/actions';
import { getAvailableTime, setAstrologistFreeTime } from '../time/actions';
import { initOnboarding as initAdvisorsOnboarding } from '../onboarding/actions';
import { getLastConnectedAstrologer } from '../selectors';
import { initAdvisorsCatalog } from '../catalog/actions';
import { addTriggerNotification } from '../notifications/actions';
import { initServices } from '../services/actions';

import { Question, Questions, TYPES } from './types';

export const setAdvisorPalmReadingData = createAction(TYPES.SET_ASTROLOGER_PALM_READING_DATA);
export const setRequestAction = createAction(TYPES.SET_REQUEST);
export const setAstrologistAction = createAction(TYPES.SET_ASTROLOGIST);
export const setAstrologistReviewsAction = createAction(TYPES.SET_ASTROLOGIST_REVIEWS);
export const setQuestionsAction = createAction(TYPES.SET_QUESTIONS);
export const setAstrologers = createAction(TYPES.SET_ASTROLOGERS);
export const setLastConnectionSessionNumber = createAction(TYPES.SET_LAST_CONNECTION_SESSION_NUMBER);
export const setLastConnectedAstrologer = createAction(TYPES.SET_LAST_CONNECTED_ASTROLOGER);
export const setPromocode = createAction(TYPES.SET_PROMOCODE);

const setLastConnectedAstrologerData = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const lastAstrologist = getLastConnectedAstrologer(state);
    const randomAiAstrologist = state.astrologers.core.astrologist;

    dispatch(setLastConnectedAstrologer(isEmpty(lastAstrologist) ? randomAiAstrologist : lastAstrologist));
  };
};

let inited = false;
export const initAstrologist = () => {
  return async (dispatch: AppDispatch) => {
    // to prevent double init with whereNavigateAfterOnboarding = 'chat'
    if (inited) {
      return false;
    }
    inited = true;

    const astrologers = await getAstrologersProfiles();

    if (!astrologers.length) {
      return false;
    }

    dispatch(setAstrologistFreeTime());

    const randomAstrologers = shuffle(astrologers);
    const activeAstrologers = randomAstrologers.filter(astrologer => astrologer.is_active);
    const randomAstrologist = activeAstrologers[Math.floor(Math.random() * activeAstrologers.length)];
    dispatch(setAstrologers(randomAstrologers));
    dispatch(setAstrologistAction(randomAstrologist));
    dispatch(requestAstrologistReviews(randomAstrologist.astrologer_id));

    dispatch(initAdvisorsOnboarding());
    dispatch(initAdvisorsCatalog());

    dispatch(initQuestions());

    await dispatch(initAstrologistPalmReading());

    await Promise.all([dispatch(getAvailableTime()), dispatch(getLastMessages())]);
    dispatch(countUnreadMessages());
    dispatch(setLastConnectedAstrologerData());
    dispatch(initServices());
  };
};

export const initQuestions = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { astrologistBackendConfig },
      },
    } = getState();
    const suggests = t('TODAY.ASTROLOGIST.QUESTIONS.ITEMS') as unknown as string[];
    const palmSuggestsText = t('TODAY.ASTROLOGIST.QUESTIONS.PALM_SUGGEST') as unknown as string;
    const texts: Questions = suggests.map<Question>(text => ({ text, metadata: { type: 'suggest' } }));
    const questions = getRandomTexts(texts, texts.length - 1);
    const chatQuestions = [...questions];
    if (astrologistBackendConfig?.palmReadingEnabled) {
      const palmSuggests = { text: palmSuggestsText, metadata: { type: 'palmRequestSuggest' } };
      chatQuestions.splice(1, 0, palmSuggests);
    }

    dispatch(setQuestionsAction({ questions, chatQuestions }));
  };
};

export const setRequest = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRequestAction(true));
  };
};

export const sendDataToAstrologers = (email: string, question: string): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const sign = selectZodiacSign(state);

    sendData(email, question, sign).then(() => dispatch(setRequest()));
  };
};

export const initAstrologistPalmReading = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      profile: {
        profileData: { userParams },
      },
    } = getState();
    const isAdvisorPalmReadingFirstSessionEnabled = remoteConfigParams?.enableAdvisorPalmReadingFirstSession || false;

    if (!isAdvisorPalmReadingFirstSessionEnabled || userParams?.is_advisor_palm_reading_completed) {
      return false;
    }

    await dispatch(getWebData());

    const state = getState();
    const {
      palmReadingDaily: { data },
    } = state;

    if (!data?.leftHandImageUrl) {
      return false;
    }

    const leftHandAdvisorData = selectLeftHandData(state);

    if (!leftHandAdvisorData?.astrologersMessage) {
      return false;
    }

    const advisorData = {
      image: data.leftHandImageUrl,
      hiddenMessage: leftHandAdvisorData.astrologersMessage,
    };

    dispatch(setAdvisorPalmReadingData(advisorData));
    dispatch(setUserParams({ is_advisor_palm_reading_completed: true }));
    return true;
  };
};

export const handleExternalTrigger = (triggerPromocode?: string) => {
  return async (dispatch: AppDispatch, getSate: AppGetState) => {
    const {
      astrologers: {
        core: { promocode: externalPromocode },
      },
      auth: { accessToken },
    } = getSate();

    const promocode = triggerPromocode?.length ? triggerPromocode : externalPromocode;

    if (!accessToken.length) {
      return false;
    }

    if (!promocode.length) {
      return false;
    }

    Analytics.trackEvent('Advisor_External_Trigger', 'Start');

    try {
      const res = await addPromocode(promocode);
      Analytics.trackEvent('Advisor_External_Trigger', 'Success', res);

      if (res?.seconds) {
        Analytics.trackEvent('TopNotification', 'Show', {
          type: EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER,
          seconds: res.seconds,
          promocode,
        });
        dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER, seconds: res.seconds }));
      }
    } catch (error) {
      console.log('ERROR handleExternalTrigger', error);
      Analytics.trackEvent('Advisor_External_Trigger', 'Fail');
    } finally {
      dispatch(getAvailableTime());
      dispatch(setPromocode(''));
    }
  };
};

export const requestAstrologistReviews = (id: Astrologist['astrologer_id']): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const astrologistReviews = getState().astrologers.core.astrologistReviews;

    if (isEmpty(astrologistReviews) || !astrologistReviews[id] || astrologistReviews[id] === null) {
      dispatch(setAstrologistReviewsAction({ id, reviews: null }));
      const reviews = await getAstrologistReviews(id);

      dispatch(setAstrologistReviewsAction({ id, reviews }));
    }
  };
};
