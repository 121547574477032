import { PLANET_IDS } from 'screens/birth-chart/constants';
import { windowHeight } from 'constants/sizes';

import { FormField } from '../interfaces';

/* General */
export enum PROFILE_PARAMS {
  NAME = 'name',
  DATE = 'date',
  PLACE = 'place',
  TIME = 'time',
}

export const IS_SMALL_SCREEN = windowHeight <= 667;

/* Onboarding */
export enum BIRTHCHARTS_COM8TY_ONB_PAGES_IDS {
  WELCOME = 'WELCOME',
  PARTNER_INFO = 'PARTNER_INFO',
  USER_INFO = 'USER_INFO',
  DESCRIPTION = 'DESCRIPTION',
  ANALYSIS = 'ANALYSIS',
}

export const DEFAULT_BIRTHCHARTS_COM8TY_ONB_FLOW = [
  BIRTHCHARTS_COM8TY_ONB_PAGES_IDS.WELCOME,
  BIRTHCHARTS_COM8TY_ONB_PAGES_IDS.PARTNER_INFO,
  BIRTHCHARTS_COM8TY_ONB_PAGES_IDS.DESCRIPTION,
  BIRTHCHARTS_COM8TY_ONB_PAGES_IDS.ANALYSIS,
];

export const FORM_FIELDS: FormField[] = [
  {
    type: PROFILE_PARAMS.NAME,
    isRequired: false,
    withOptions: false,
    mode: 'text',
  },
  {
    type: PROFILE_PARAMS.DATE,
    isRequired: true,
    withOptions: true,
    mode: 'date',
  },
  {
    type: PROFILE_PARAMS.PLACE,
    isRequired: false,
    withOptions: true,
    mode: 'text',
  },
  {
    type: PROFILE_PARAMS.TIME,
    isRequired: false,
    withOptions: true,
    mode: 'time',
  },
];

export const DEFAULT_ANIMATION_DURATION = 7;
export const ANALYSIS_LOADER_DURATION = 10;
export const ANALYSIS_TEXT_INTERPOLATION = [
  {
    inputRange: [0, 0.1, 0.15, 1],
    outputRange: [1, 1, 0, 0],
  },
  {
    inputRange: [0, 0.15, 0.2, 0.35, 0.4, 1],
    outputRange: [0, 0, 1, 1, 0, 0],
  },
  {
    inputRange: [0, 0.4, 0.45, 0.6, 0.65, 1],
    outputRange: [0, 0, 1, 1, 0, 0],
  },
  {
    inputRange: [0, 0.65, 0.7, 0.85, 0.9, 1],
    outputRange: [0, 0, 1, 1, 0, 0],
  },
  {
    inputRange: [0, 0.9, 1],
    outputRange: [0, 0, 1],
  },
];

/* Daily Love Horoscopes*/
export const DAILY_LOVE_HOROSCOPES_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

export const COMPATIBILITY_PLANETS: PLANET_IDS[] = [
  PLANET_IDS.SUN,
  PLANET_IDS.MOON,
  PLANET_IDS.MARS,
  PLANET_IDS.MERCURY,
  PLANET_IDS.VENUS,
  PLANET_IDS.JUPITER,
  PLANET_IDS.SATURN,
];
