export const LOADING = 'loading';
export const ONBOARDING_WELCOME = 'onboarding-welcome';
export const ONBOARDING_DATE = 'onboarding-date';
export const ONBOARDING_LOADER = 'onboarding-loader';
export const ONBOARDING_GENDER = 'onboarding-gender';
export const ONBOARDING_NAME = 'onboarding-name';
export const ONBOARDING_RELATIONSHIP = 'onboarding-relationship';
export const ONBOARDING_PLACE = 'onboarding-place';
export const ONBOARDING_TIME = 'onboarding-time';
export const ONBOARDING_ATT = 'onboarding-att';
export const DASHBOARD = 'dashboard';
export const HOROSCOPES = 'horoscopes';
export const HOROSCOPE = 'horoscope';
export const MAGIC_BALL = 'magic-ball';
export const BIORHYTHM = 'biorhythm';
export const TAROT = 'tarot';
export const TAROT_LAYOUT = 'tarot-layout';
export const TAROT_CARD_OF_THE_DAY = 'tarot-card-of-the-day';
export const TAROT_CALENDAR = 'tarot-calendar';
export const TAROT_CARDS_DESCRIPTION = 'tarot-cards-description';
export const TAROT_YES_OR_NO = 'tarot-yes-or-no';
export const PALM_READING_DAILY = 'palm-reading-daily';
export const PALM_READING_DAILY_CAMERA = 'palm-reading-daily-camera';
export const COMPATIBILITY = 'compatibility';
export const COMPATIBILITY_RESULT = 'compatibility-result';
export const SETTINGS = 'settings';
export const PROFILE = 'profile';
export const EDIT_PROFILE = 'edit-profile';
export const NAME_SETTINGS = 'name-settings';
export const GENDER_SETTINGS = 'gender-settings';
export const RELATIONSHIP_SETTINGS = 'relationship-settings';
export const PLACE_SETTINGS = 'place-settings';
export const PRIVACY_POLICY = 'privacy-policy';
export const TERMS = 'terms';
export const DAILY = 'daily';
export const MAIN = 'main';
export const DIVINATIONS = 'divinations';
export const ADVANTAGES = 'advantages';
export const DEV = 'dev';
export const SUPPORT = 'support';
export const TALLY_QUESTIONNAIRE = 'tally-questionnaire';
export const LANGUAGE = 'language';
export const TODAY_MATCHES = 'today-matches';
export const TODAY_FEATURE = 'today-feature';
export const PERSONAL_READINGS = 'personal-readings';
export const LUNAR_CALENDAR = 'lunar-calendar';

export const COMPATIBILITY_DASHBOARD = 'compatibility_dashboard';
export const BEST_MATCHES = 'best-matches';
export const SIGN_BEST_MATCHES = 'sign-best-matches';

export const QUESTIONNAIRE = 'questionnaire';
export const GUIDES = 'guides';
export const GUIDE_PAGE = 'guide-pages';
export const GUIDES_MAGIC_SCREEN = 'guides-magic-screen';
export const GUIDES_SESSION_SCREEN = 'session-screen';
export const CONGRATULATIONS = 'congratulations';

export const SUBSCRIPTION_TIMER = 'subscription-timer';
export const SUBSCRIPTION_SPECIAL_OFFER = 'subscription-special-offer';
export const SUBSCRIPTION_PROMO_OFFER = 'subscription-promo-offer';
export const SUBSCRIPTION_VERTICAL_PRODUCTS = 'subscription-vertical-products';
export const SUBSCRIPTION_FAMILY = 'subscription-family';
export const SUBSCRIPTION_PALM = 'subscription-palm';
export const SUBSCRIPTION_POST = 'subscription-post';
export const SUBSCRIPTION_VERTICAL_ONE_PRODUCT = 'subscription-vertical-one-product';
export const SUBSCRIPTION_WEB = 'subscription-web';

export const DATING_CALENDAR = 'dating-calendar';
export const HIDDEN_MENU = 'hidden-menu';
export const STORIES = 'stories';
export const ASTROLOGERS = 'astrologers';
export const ASTROLOGER_REPORT = 'astrologer-report';
export const ASTROLOGER_EMAIL = 'astrologer-email';
export const ASTROLOGER_WAIT = 'astrologer-wait';

export const BIRTH_CHART = 'birth_chart';
export const BIRTH_CHART_DASHBOARD = 'birth-chart-dashboard';
export const BIRTH_CHART_TRANSITS = 'birth-chart-transits';
export const BIRTH_CHART_CHARTS = 'birth-chart-charts';
export const BIRTH_CHART_PLANET_INFO = 'birth-chart-planet-info';
export const BIRTH_CHART_TRANSIT_DETAILS = 'birth-chart-transit-details';
export const BIRTH_CHART_ONBOARDING = 'birth-chart-onboarding';
export const BIRTH_CHART_ABOUT_TRANSITS_ASPECTS = 'birth-chart-about-transits-aspects';

export const AUTH_BY_EMAIL = 'auth-by-email';
export const AUTH_SUCCESS = 'auth-success';

export const ADVISORS = 'advisors';
export const ADVISORS_CONNECTION = 'advisors-connection';
export const ADVISORS_CHAT = 'advisors-chat';
export const ADVISORS_CHATS = 'advisors-chats';
export const ADVISORS_PALM_SCAN = 'advisors-palm-scan';
export const ADVISORS_ONBOARDING = 'advisors-onboarding';
export const ADVISORS_CATALOG = 'advisors-catalog';
export const ADVISORS_ALL = 'advisors-all';
export const ADVISORS_QUESTIONS = 'advisors-question';
export const ADVISORS_CHAT_READ = 'advisors-chat-read';

export const ADVISORS_PACKS = 'advisors-packs';
export const ADVISORS_PACK_SPECIAL_OFFER = 'advisors-pack-special-offer';
export const ADVISORS_PACK_WELCOME_OFFER = 'advisors-pack-welcome-offer';

export const ASTRO_CALENDAR = 'astro-calendar';

export const ADVISORS_TABS = 'advisors-tabs';
export const ADVISORS_TABS_STACK = 'advisors-tabs-stack';
export const ADVISORS_TABS_CHATS = 'advisors-tabs-chats';
export const ADVISORS_TABS_SERVICES = 'advisors-tabs-services';
export const ADVISORS_SERVICE = 'advisors-service';

export const ADVISOR_PAGE = 'advisor-page';
export const ADVISOR_TABS_STACK = 'advisor-tabs-stack';
export const ADVISOR_TAB_CHAT = 'advisor-tab-chat';
export const ADVISOR_TAB_HOROSCOPE = 'advisor-tab-horoscope';
export const ADVISOR_TAB_ABOUT = 'advisor-tab-about';
export const ADVISOR_TAB_REVIEWS = 'advisor-tab-reviews';

export const BIRTHCHARTS_COM8TY = 'birthcharts-com8ty';
export const BIRTHCHARTS_COM8TY_EDIT = 'birthcharts-com8ty-edit';
export const BIRTHCHARTS_COM8TY_ONB = 'birthcharts-com8ty-onb';

export const REPORTS_WEB_VIEW = 'reports-web-view';

export const TAB_BAR_ROUTES = [
  DAILY,
  HOROSCOPES,
  COMPATIBILITY,
  COMPATIBILITY_DASHBOARD,
  DIVINATIONS,
  GUIDES,
  BIRTH_CHART_DASHBOARD,
  BIRTH_CHART_CHARTS,
  BIRTH_CHART_TRANSITS,
  ASTROLOGERS,
];

export const FULL_ONBOARDING_ROUTES = [
  ONBOARDING_DATE,
  ONBOARDING_GENDER,
  ONBOARDING_NAME,
  ONBOARDING_RELATIONSHIP,
  ONBOARDING_LOADER,
  ONBOARDING_WELCOME,
  ONBOARDING_RELATIONSHIP,
  ONBOARDING_TIME,
  AUTH_BY_EMAIL,
];

const SETTINGS_ROUTES = [SETTINGS, NAME_SETTINGS, GENDER_SETTINGS, RELATIONSHIP_SETTINGS, PLACE_SETTINGS];

export const SUBSCRIPTIONS = [
  SUBSCRIPTION_TIMER,
  SUBSCRIPTION_SPECIAL_OFFER,
  SUBSCRIPTION_PROMO_OFFER,
  SUBSCRIPTION_VERTICAL_PRODUCTS,
  SUBSCRIPTION_FAMILY,
  SUBSCRIPTION_PALM,
  SUBSCRIPTION_POST,
  SUBSCRIPTION_VERTICAL_ONE_PRODUCT,
  SUBSCRIPTION_WEB,
  ADVISORS_PACKS,
];

export const CONTENT_EXCLUDE_PAGES = [LOADING, DAILY, ...FULL_ONBOARDING_ROUTES, ...SUBSCRIPTIONS];

export const ADVISORS_PACK_ROUTES = [ADVISORS_PACKS, ADVISORS_PACK_SPECIAL_OFFER, ADVISORS_PACK_WELCOME_OFFER];

// Be sure to add pages to the array for which the Rate-Us modal is not relevant
export const RATE_US_EXCLUSIONS_PAGES = [
  LOADING,
  PRIVACY_POLICY,
  TERMS,
  GUIDES_MAGIC_SCREEN,
  DEV,
  HIDDEN_MENU,
  STORIES,
  CONGRATULATIONS,
  HOROSCOPES,
  DIVINATIONS,
  GUIDES,
  ASTROLOGERS,
  ...SUBSCRIPTIONS,
  ...FULL_ONBOARDING_ROUTES,
  ...SETTINGS_ROUTES,
];

export const BACKGROUND_EXCLUDE_ROUTES = [LOADING, PALM_READING_DAILY_CAMERA, ...SUBSCRIPTIONS];

export const MODALS_EXCLUDE_ROUTES = [
  ...ADVISORS_PACK_ROUTES,
  ADVISORS,
  ADVISORS_CONNECTION,
  ADVISORS_CHAT,
  ADVISORS_PALM_SCAN,
  ADVISORS_CHATS,
  ADVISORS_ONBOARDING,
  PALM_READING_DAILY,
  PALM_READING_DAILY_CAMERA,
];

export const ASTROEVENT_EXCLUDE_ROUTES = [
  ...SUBSCRIPTIONS,
  ...FULL_ONBOARDING_ROUTES,
  ...ADVISORS_PACK_ROUTES,
  ADVISORS,
  ASTROLOGERS,
  ADVISORS_CONNECTION,
  ADVISORS_CHAT,
  ADVISORS_PALM_SCAN,
  ADVISORS_CHATS,
  PALM_READING_DAILY,
  PALM_READING_DAILY_CAMERA,
];

export const TAB_SCREENS = [DAILY, HOROSCOPES, DIVINATIONS, COMPATIBILITY, GUIDES, COMPATIBILITY_DASHBOARD, BIRTH_CHART, ASTROLOGERS, ADVISORS];

export enum PATH_NAMES {
  readings = 'readings',
  palm = 'palm',
  tarot = 'tarot',
  advisors = 'advisors',
  chat = 'chat',
  'birth-chart' = 'birth-chart',
  compatibility = 'compatibility',
}

export const PATH_ROUTES_MAP: { [key in PATH_NAMES]: string } = {
  [PATH_NAMES.readings]: DIVINATIONS,
  [PATH_NAMES.palm]: PALM_READING_DAILY,
  [PATH_NAMES.tarot]: TAROT,
  [PATH_NAMES.chat]: ADVISORS_CHAT,
  [PATH_NAMES.advisors]: ADVISORS,
  [PATH_NAMES.compatibility]: COMPATIBILITY_DASHBOARD,
  [PATH_NAMES['birth-chart']]: BIRTH_CHART,
};

export const ADVISORS_ROUTES = [
  ADVISORS,
  ADVISORS_CONNECTION,
  ADVISORS_CHAT,
  ADVISORS_CHATS,
  ADVISORS_PALM_SCAN,
  ADVISORS_ONBOARDING,
  ADVISORS_CATALOG,
  ADVISORS_ALL,
  ADVISORS_QUESTIONS,
  ADVISORS_PACKS,
];

export const BIRTH_CHART_ROUTES = [
  BIRTH_CHART,
  BIRTH_CHART_DASHBOARD,
  BIRTH_CHART_TRANSITS,
  BIRTH_CHART_CHARTS,
  BIRTH_CHART_PLANET_INFO,
  BIRTH_CHART_TRANSIT_DETAILS,
  BIRTH_CHART_ONBOARDING,
  BIRTH_CHART_ABOUT_TRANSITS_ASPECTS,
];

export const RATE_US_EXCLUDE_ROUTES = [PALM_READING_DAILY_CAMERA, ADVISORS_CHAT, ...ADVISORS_PACK_ROUTES];
