import { ResetUserData } from '../types';

export enum TYPES {
  SET_ASTROEVENT_COUNTERS = 'ASTROLOGERS/SET_ASTROEVENT_COUNTERS',
  SET_FREE_CONSULTATION_SHOWED = 'ASTROLOGERS/SET_FREE_CONSULTATION_SHOWED',
}

export interface State {
  astroeventCounters: {
    resetCount: number;
    withoutConnections: number;
    withConnections: number;
  };
  freeConsultationShowed: boolean;
}

interface SetAstroeventCounters {
  type: typeof TYPES.SET_ASTROEVENT_COUNTERS;
  payload: {
    resetCount?: number;
    withoutConnections?: number;
    withConnections?: number;
  };
}

interface SetFreeConsultationShowed {
  type: typeof TYPES.SET_FREE_CONSULTATION_SHOWED;
  payload: boolean;
}

export type ActionType = SetAstroeventCounters | SetFreeConsultationShowed | ResetUserData;
