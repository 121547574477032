import { createAction } from 'redux-actions';

import * as ChatApi from 'api/astrology-chat';
import { Balances } from 'api/astrology-chat/constants';
import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';

import { rewardForRenewHandler, showAutoRefillModal } from '../modals/actions';

import { TYPES } from './types';

export const setTimerTooltipVisibility = createAction(TYPES.SET_TOOLTIP_VISIBILITY);
export const setTimerTooltipClosed = createAction(TYPES.SET_TOOLTIP_CLOSED);
export const setAvailableSeconds = createAction(TYPES.SET_AVAILABLE_SECONDS);
export const setTimePaused = createAction(TYPES.SET_TIME_PAUSED);
export const setAwaitingNewTimeBalance = createAction(TYPES.SET_AWAITING_NEW_TIME_BALANCE);
export const setLastTransactionDate = createAction(TYPES.SET_LAST_TRANSACTION_DATE);
const setBalancesAction = createAction(TYPES.SET_BALANCES);

export const chargeTime = (seconds: number, chatId: number) => {
  return async (dispatch: AppDispatch) => {
    const timeBalance = await ChatApi.chargeTime(seconds, chatId);

    dispatch(setBalances(timeBalance));
    return timeBalance;
  };
};

export const dailyRewards = (seconds: number) => {
  return async (dispatch: AppDispatch) => {
    const timeBalance = await ChatApi.dailyRewards(seconds);
    dispatch(setBalances(timeBalance));
  };
};

export const setBalances = ({ balance = 0, freeBalance = 0 }: Balances) => {
  return (dispatch: AppDispatch) => {
    dispatch(
      setBalancesAction({
        balance,
        freeBalance,
      }),
    );
  };
};

export const addFreeTime = (time: number, purchased: boolean) => {
  return async (dispatch: AppDispatch) => {
    await ChatApi.addFreeTime(time, purchased);
    dispatch(getAvailableTime());
    return true;
  };
};

export const setAstrologistFreeTime = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { purchased },
      remoteConfig: {
        remoteConfigParams: {
          astrologistConfig: { freeMinutes, freeMinutesForFreeUsers },
        },
      },
    } = getState();
    const timeBalance = await ChatApi.getTimeBalance();

    if (!timeBalance.isRewardedForUnpaidUser && !purchased) {
      await dispatch(addFreeTime(freeMinutesForFreeUsers * 60, purchased));
    }

    if (!timeBalance.isRewardedForFirstUse && purchased) {
      await dispatch(addFreeTime(freeMinutes * 60, purchased));
    }
  };
};

export const countChatTime = (seconds: number, chargeSeconds?: number) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { currentChatId, currentAstrologerId },
      },
      profile: {
        profileData: { device_id: idfm, id: userId },
      },
    } = getState();

    if (seconds <= 0) {
      Analytics.trackEvent('Astrologist', 'Time_Up', { userId, idfm, chatId: currentChatId, astrologerId: currentAstrologerId });

      const timeBalance = await ChatApi.getTimeBalance();
      await dispatch(chargeTime(timeBalance.balance, currentChatId));

      dispatch(showAutoRefillModal());
    }

    if (chargeSeconds) {
      dispatch(chargeTime(chargeSeconds, currentChatId));
    }

    dispatch(setAvailableSeconds(seconds));
  };
};

export const getAvailableTime = () => {
  return async (dispatch: AppDispatch) => {
    const timeBalance = await ChatApi.getTimeBalance();

    dispatch(setAvailableSeconds(timeBalance.balance));
    dispatch(rewardForRenewHandler(!timeBalance?.isRewardedForRenew));
    dispatch(setBalances(timeBalance));
    return timeBalance;
  };
};
