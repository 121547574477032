import { createAction } from 'redux-actions';
import queryString from 'query-string';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { getWebActiveSubscription } from 'store/billing/selectors';
import { showModal } from 'store/modals/actions';
import Analytics from 'analytics';
import { getWebSubs, getWebAllSubs, getWebOneTimePurchases } from 'api/purchases';
import { PRO, SKU, WEB_SUBSCRIPTION_STATUS } from 'constants/billing';
import * as MODALS from 'constants/modals';
import { setOnboardingFlow } from 'store/remote-config/actions';
import { setAstrologistFreeTime } from 'store/astrologers/time/actions';

import { BILLING_TYPES } from './types';
import { trackEvent, cancelSubscription, fetchPaymentMethod } from './general-actions';

export const setPending = createAction(BILLING_TYPES.SET_PENDING);
export const setWebActiveTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
export const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
export const setWebOneTimePurchases = createAction(BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES);
export const setTransactions = createAction(BILLING_TYPES.SET_TRANSACTIONS);
export const setPurchased = createAction(BILLING_TYPES.SET_PURCHASED);
export const setPurchaseDate = createAction(BILLING_TYPES.SET_PURCHASE_DATE);
export const setProducts = createAction(BILLING_TYPES.SET_PRODUCTS);
export const setProductsLoadSuccess = createAction(BILLING_TYPES.SET_PRODUCTS_LOAD_SUCCESS);
export const setTempPurchaseActive = createAction(BILLING_TYPES.SET_TEMP_PURCHASE_ACTIVE);
export const setWebSubscriptionShowed = createAction(BILLING_TYPES.SET_WEB_SUBSCRIPTION_SHOWED);

export const initBilling = (): AppThunk<any> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setPending(true));
    const startTime = new Date().getTime();
    trackEvent('init_start', { startTime });

    try {
      await dispatch(fetchPurchases());
      const eventData = { executionTime: Date.now() - startTime };
      trackEvent('init_finish', eventData);

      dispatch(setupPurchasedState());
      dispatch(verifySubscriptions());

      const {
        remoteConfig: { error: isFetchRemoteConfigError },
        billing: { purchased },
      } = getState();

      if (isFetchRemoteConfigError && purchased) {
        dispatch(setOnboardingFlow([]));
      }

      dispatch(setPending(false));
    } catch (error) {
      trackEvent('init_error', { error });
      console.log(`[Billing] Init failed ${error}`);
      dispatch(setPending(false));
      return [];
    }
  };
};

export const fetchPurchases = () => {
  return async (dispatch: AppDispatch) => {
    const [transactions, oneTimePurchases] = await Promise.all([getWebSubs(), getWebOneTimePurchases()]);
    dispatch(setWebActiveTransactions(transactions));
    dispatch(setWebOneTimePurchases(oneTimePurchases));
    dispatch(fetchPaymentMethod());
  };
};

export const addFakePurchase = () => {
  return (dispatch: AppDispatch) => {
    dispatch(setPurchased({ purchased: true, purchasedAstrologers: true }));
    dispatch(setAstrologistFreeTime());
  };
};

export const verifySubscriptions = () => {
  return async (dispatch: AppDispatch) => {
    const allSubscriptions = await getWebAllSubs();

    const subscriptions = allSubscriptions.map((subscription: any) => {
      if (subscription.active) {
        if (subscription.canceled_at) {
          subscription.status = WEB_SUBSCRIPTION_STATUS.CANCELED;
        } else if (subscription.trial_end_at === subscription.expire_at) {
          subscription.status = WEB_SUBSCRIPTION_STATUS.TRIAL;
        } else {
          subscription.status = WEB_SUBSCRIPTION_STATUS.ACTIVE;
        }
      } else {
        subscription.status = WEB_SUBSCRIPTION_STATUS.EXPIRED;
      }

      return subscription;
    });

    dispatch(setWebAllSubscriptions(subscriptions));
    dispatch(reactivationHandler());

    return subscriptions;
  };
};

export const verifyMobilePurchases = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const reactivationHandler = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      billing: { webAllSubscriptions },
    } = state;

    const activeSubscription = getWebActiveSubscription(state);

    const isReactivate = webAllSubscriptions.find(s => [WEB_SUBSCRIPTION_STATUS.CANCELED, WEB_SUBSCRIPTION_STATUS.EXPIRED].includes(s?.status));

    if (!activeSubscription && isReactivate) {
      dispatch(showModal(MODALS.REACTIVATION));

      return true;
    }

    return false;
  };
};

const setupPurchasedState = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { transactions, webTransactions, isTempPurchaseActive },
    } = getState();

    if (webTransactions.length && isTempPurchaseActive) {
      dispatch(setTempPurchaseActive(false));
    }

    let purchased = !!webTransactions?.length || transactions?.some(item => item?.productId?.indexOf(SKU) === 0) || isTempPurchaseActive;

    const purchasedAstrologers = transactions?.some(item => item?.productId?.includes?.(PRO));
    const p = queryString.parse(window.location.search);
    purchased = purchased || p.purchased !== undefined;

    dispatch(setPurchased({ purchased, purchasedAstrologers }));

    dispatch(setAstrologistFreeTime());

    const userType = purchased || purchasedAstrologers ? 'subscriber' : 'free';
    Analytics.setUserProperty('user_type', userType);
  };
};

export const subscribe = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchase = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchaseWithOffer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const purchaseAstrologer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const restore = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const getProductDetails = (productId: string): any => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { products },
    } = getState();

    return products.find(item => item.productId === productId);
  };
};

export const cancelSubscriptions = () => {
  return (dispatch: AppDispatch) => {
    return dispatch(cancelSubscription());
  };
};
