import DeviceProps from '@magnus/react-native-device-props';
import EvTruck from '@magnus/react-native-evtruck';
import Mutator from '@magnus/react-native-mutator';
import * as Sentry from '@sentry/browser';
import Session from '@wowmaking/react-native-analytics/src/core/helpers/session';
import * as Firebase from 'firebase/analytics';
import queryString from 'query-string';
import AsyncStorage from '@react-native-async-storage/async-storage';
import type { AnyAction } from 'redux';
import type { AnalyticsType } from '@wowmaking/react-native-analytics/types/core';

import { processWebUUID } from 'store/auth/actions';
import { setWebMode } from 'store/app/actions';
import { setPromocode } from 'store/astrologers/core/actions';
import type { StoreType } from 'store';

import packageInfo from '../../web/package.json';

import { SHOW_EVENTS_LOGS } from './const';
import type { AnalyticsModule, WebTransaction } from './types';

const KEY_COHORT_DATE = '@WRNA/COHORT_DATE';
const USER_PROPERTIES_KEY = '@RNMV/USER_PROPERTIES';
const isGRA = window.navigator?.userAgent?.includes?.('Google-Read-Aloud');

if (!isGRA && ['localhost', '0.0.0.0', '127.0.0.1'].indexOf(window.location.hostname) === -1) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1,
  });
}

let store: StoreType;

const analyticsModule: AnalyticsModule = {
  cohortDate: null,

  putStore(s) {
    store = s;
  },

  init() {
    if (isGRA) {
      return Promise.resolve();
    }

    DeviceProps.getIDFM().then(idfm => {
      Sentry.setUser({ id: idfm });

      window.dataLayer?.push({
        idfm,
      });
    });

    DeviceProps.setAppVersion(packageInfo.version);

    EvTruck.init({
      token: process.env.REACT_APP_MAGNUS_TOKEN || '',
    });

    return Session.init(this as unknown as AnalyticsType)
      .then(() => {
        const p = queryString.parse(window.location.search);
        const path = window.location.pathname;
        const partition = path.slice(1);
        const paramsForDeletion: string[] = [];

        Mutator.setAttribution({ source: String(p.utm_source), campaign_id: String(p.campaign_id) });

        this.setUserProperty('domain', window.location.hostname);

        if (p.utm_source) {
          this.setUserProperty('utm_source', String(p.utm_source));
        }

        if (p.campaign_id) {
          this.setUserProperty('campaign_id', String(p.campaign_id));
        }

        if (p.ua_creative_topic) {
          this.setUserProperty('ua_creative_topic', String(p.ua_creative_topic));
        }

        if (partition) {
          this.setUserProperty('partition', partition);
        }

        if (p.source) {
          this.setUserProperty('web_source', String(p.source));
        }

        if (p.idfm && typeof p.idfm === 'string') {
          store.dispatch(processWebUUID(p.idfm) as unknown as AnyAction);
        }

        if (p.promocode) {
          store.dispatch(setPromocode(p.promocode));
          paramsForDeletion.push('promocode');
        }

        if (p.testmode) {
          this.setUserProperty('testmode', String(p.testmode));
        }

        if (p.mode !== undefined) {
          this.setUserProperty('mode', String(p.mode));
          store.dispatch(setWebMode(p.mode));
        }

        this.setUserProperty('timezone', new Date().getTimezoneOffset() / 60);

        if (p.reset !== undefined) {
          paramsForDeletion.push('reset');
          setInterval(() => {
            window.localStorage.clear();
          }, 10);
        }

        if (paramsForDeletion.length) {
          setTimeout(() => {
            paramsForDeletion.forEach(key => {
              delete p[key];
            });

            window.location.href = queryString.stringifyUrl({
              url: window.location.origin + window.location.pathname,
              query: p,
            });
          }, 1000);
        }

        return p;
      })
      .then(() => this.getCohortDate())
      .then(res => {
        this.cohortDate = res;
      });
  },

  onAdjustDeferredDeepLink() {},

  setAttributionUserProperties() {},

  setUserProperty(name, value) {
    EvTruck.setUserProperty(name, value);
    Firebase.setUserProperties(Firebase.getAnalytics(), { [name]: value });
  },

  async getUserProperty(key) {
    try {
      const stringifiedUserProperties = await AsyncStorage.getItem(USER_PROPERTIES_KEY);

      if (!stringifiedUserProperties) {
        throw 'User Properties value not found';
      }

      const userProperties = await JSON.parse(stringifiedUserProperties);

      return userProperties?.[key];
    } catch (error) {
      console.log('ERROR getUserProperty', error);
    }
  },

  trackEvent(category, action, params) {
    const event = `${category}_${action}`;
    params = {
      ...params,
      session_number: this.getSessionNumber(),
    };

    if (__DEV__ && SHOW_EVENTS_LOGS) {
      console.log('[Platform: WEB]:', event, params);
    }

    EvTruck.trackEvent(event, params);
    window.dataLayer?.push({ event, ...params }); // GTM
    Firebase.logEvent(Firebase.getAnalytics(), event, params);
  },

  track(...args) {
    const [event, params, options] = args;

    const [category, ...rest] = event.split('_');
    const action = [...rest].join('_');

    this.trackEvent(category, action, params, options);
  },

  trackViewProductEvent() {},

  trackPurchaseEvent(transaction) {
    if (isWebTransaction(transaction)) {
      EvTruck.trackEvent('ecommerce_purchase', {
        transactionId: transaction.transactionId,
        productId: transaction.productId,
        value: transaction.revenue,
        currency: transaction.currency,
      });

      window.dataLayer?.push({
        event: 'purchase',
        currency: transaction.currency,
        value: Number(transaction.revenue),
        transaction_id: transaction.transactionId,
        product_id: transaction.productId,
      });
    }
  },

  getSessionNumber() {
    return Session.getNumber();
  },

  getAppRef() {
    const p = queryString.parse(window.location.search);

    if (p.ref) {
      return String(p.ref);
    }
  },

  getAdjustDeferredDeeplink() {
    return Promise.resolve(null);
  },

  async getCohortDate() {
    try {
      const res = await AsyncStorage.getItem(KEY_COHORT_DATE);
      return res;
    } catch (error) {
      console.log('ERROR getCohortDate', error);
      return null;
    }
  },
};

const isWebTransaction = (t: any): t is WebTransaction => {
  return !!t && 'currency' in t && 'revenue' in t;
};

export default analyticsModule;
