import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import { AppDispatch, RootState } from 'store';
import { ADVISORS_ROUTES, ADVISORS } from 'constants/routes';
import { removeTriggerNotifications } from 'store/astrologers/notifications/actions';
import { navigate } from 'store/navigation/actions';
import TIMER from 'assets/icons/timer/timer.png';
import { formatMinutes } from 'utils/astrologist';
import { t } from 'localization';
import { selectTriggerNotificationSeconds } from 'store/astrologers/notifications/selectors';
import Text from 'components/text';
import { DARK_TEAL } from 'constants/colors';

import BaseTriggerNotification from './base';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from '.';

interface Props {}

const SuccessAfterTrigger: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const seconds = useSelector((state: RootState) => selectTriggerNotificationSeconds(state, EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER));
  const currentRoute = useSelector((state: RootState) => state.navigation.currentRoute);

  const handlePress = () => {
    if (!ADVISORS_ROUTES.includes(currentRoute)) {
      navigate(ADVISORS);
    }

    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER]));
  };

  const handleClose = () => {
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER]));
  };

  if (!seconds) {
    return null;
  }

  return (
    <BaseTriggerNotification onPress={handlePress} onClose={handleClose} icon={TIMER} type={EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER}>
      <Text style={styles.text}>{t('TRIGGER_NOTIFICATION.TRIGGER_SUCCESS', { minutes: formatMinutes(seconds) })}</Text>
    </BaseTriggerNotification>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: fs(12),
    fontWeight: '600',
    color: DARK_TEAL,
    lineHeight: fs(20),
    flex: 1,
    height: '100%',
  },
});

export default memo(SuccessAfterTrigger);
