import React, { FC, memo, PropsWithChildren } from 'react';
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity, View } from 'react-native';
import { paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import { BEIGE, DARK_GREY_BLUE, DUSK_BLUE } from 'constants/colors';
import CLOSE from 'assets/icons/close.png';
import NEXT from 'assets/icons/arrows/next-arrow.png';
import Analytics from 'analytics';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from '..';

interface Props extends PropsWithChildren {
  icon: ImageSourcePropType;
  type: EXTERNAL_TRIGGER_NOTIFICATIONS;
  onPress: () => void;
  onClose?: () => void;
}

const BaseTriggerNotification: FC<Props> = ({ children, icon, onPress, onClose, type }) => {
  const handlePress = () => {
    Analytics.trackEvent('TopNotification', 'Click', { type });
    onPress();
  };

  const handleClose = () => {
    Analytics.trackEvent('TopNotification', 'Close', { type });
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <View style={styles.wrapper}>
      {typeof onClose === 'function' && (
        <TouchableOpacity onPress={handleClose} style={styles.closeButton}>
          <Image source={CLOSE} style={styles.closeIcon} />
        </TouchableOpacity>
      )}
      <TouchableOpacity style={styles.container} onPress={handlePress}>
        <Image source={icon} style={styles.icon} />
        {children}
        <View style={styles.nextContainer}>
          <Image source={NEXT} style={styles.nextIcon} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    backgroundColor: BEIGE,
    paddingHorizontal: paddingHorizontal(10),
    paddingVertical: paddingVertical(5),
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
  closeButton: {
    width: sw(15),
    height: sw(15),
    marginRight: paddingHorizontal(10),
  },
  closeIcon: {
    tintColor: DUSK_BLUE,
    width: '100%',
    height: '100%',
  },
  nextContainer: {
    width: sw(10),
    height: sw(17),
    marginLeft: paddingHorizontal(10),
  },
  nextIcon: {
    tintColor: DARK_GREY_BLUE,
    width: '100%',
    height: '100%',
  },
  icon: {
    marginRight: paddingHorizontal(10),
    width: sw(30),
    height: sw(30),
  },
});

export default memo(BaseTriggerNotification);
