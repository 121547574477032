import { FC } from 'react';

import SuccessAfterTrigger from './success-after-trigger';
import InstallMobile from './install-mobile';
import GoToMobileApp from './go-to-mobile-app';

export enum EXTERNAL_TRIGGER_NOTIFICATIONS {
  SUCCESS_AFTER_TRIGGER = 'success_after_trigger',
  INSTALL_MOBILE_APP = 'install_mobile_app',
  GO_TO_MOBILE_APP = 'go_to_mobile_app',
}

export const EXTERNAL_TRIGGER_NOTIFICATIONS_MAP: { [key in EXTERNAL_TRIGGER_NOTIFICATIONS]: FC } = {
  [EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER]: SuccessAfterTrigger,
  [EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]: InstallMobile,
  [EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP]: GoToMobileApp,
};
