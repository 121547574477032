import { createAction } from 'redux-actions';
import _ from 'lodash';
import { Platform } from 'react-native';
import moment from 'moment';
import DeviceProps from '@magnus/react-native-device-props';

import { t, t2 } from 'localization';
import Analytics from 'analytics';
import { Astrologist, ChatPlatforms, HistoryItem } from 'api/astrology-chat/constants';
import { startChat, addMessage, getHistory, getChats, pingChat, readMessages } from 'api/astrology-chat';
import useTemplate from 'api/astrology-chat/use-template';
import { sendAstrologersQuestion } from 'api/astrologers';
import { ADVISOR_PAGE, ADVISORS, ADVISORS_CONNECTION, MAIN } from 'constants/routes';
import { ADVISOR_NOTIFICATIONS } from 'constants/notification-center';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { back, canGoBack, navigate, reset } from 'store/navigation/actions';
import { selectRightHandData } from 'store/palm-reading-daily/selectors';
import { astrologersMonetizationHandler } from 'store/astrologers/monetization/actions';
import { ASTROLOGERS_OPEN_PLACE, ASTROLOGERS_PROMO_PLACES, TEMPLATE_IDS } from 'screens/advisors/constants';
import { ASTROLOGERS_TRIGGERS_TYPES } from 'screens/advisors/screens/monetization/constants/interfaces';
import { START_CHAT_PLACE } from 'screens/advisors/constants';
import { countDaysDiff, formatSeconds, generateSectionData, transformMessages } from 'utils/astrologist';
import { isStandalone } from 'utils/pwa';
import { getAstroeventMessage } from 'store/transits/selectors';

import { setAstroeventCounters, showAutoRefillModal } from '../modals/actions';
import { setAdvisorPalmReadingData, setLastConnectionSessionNumber } from '../core/actions';
import { getLastConnectedAstrologer, getPalmReadingMetadata } from '../selectors';
import { resetAstrologistNotifications, setNotification, setNotificationPressed } from '../notifications/actions';
import { setCompleted } from '../onboarding/actions';
import { setTimePaused, setTimerTooltipVisibility, chargeTime } from '../time/actions';
import { setActiveServicesTab } from '../services/actions';
import { Question } from '../core/types';

import { TYPES } from './types';
import { handleEventsForSpecificMessages } from './utils';

const resetAllChats = createAction(TYPES.RESET_ALL_CHATS);
const setPendingChats = createAction(TYPES.SET_PENDING_CHATS);
const setCurrentChats = createAction(TYPES.SET_CURRENT_CHATS);
const setUnreadChatCount = createAction(TYPES.SET_UNREAD_CHAT_COUNT);
const setIsTyping = createAction(TYPES.SET_CHAT_IS_TYPING);
const setIsRecording = createAction(TYPES.SET_CHAT_IS_RECORDING);
export const setHiddenMessage = createAction(TYPES.SET_HIDDEN_MESSAGE);
export const setChatMessages = createAction(TYPES.SET_CHAT_MESSAGES);
export const setCurrentChatId = createAction(TYPES.SET_CURRENT_CHAT_ID);
export const setQuestionAction = createAction(TYPES.SET_QUESTION);
export const setChatReviewCompleted = createAction(TYPES.SET_CHAT_REVIEW_COMPLETED);
export const setStartChatPlaceAction = createAction(TYPES.SET_START_CHAT_PLACE);
export const setCurrentAstrologerId = createAction(TYPES.SET_CURRENT_ASTROLOGER_ID);
export const setIsPalmReadingOffer = createAction(TYPES.SET_IS_PALM_READING_OFFER);
export const setPalmPhoto = createAction(TYPES.SET_PALM_PHOTO);
export const setSuggestsVisible = createAction(TYPES.SET_SUGGESTS_VISIBLE);
const setWasSessionConversation = createAction(TYPES.SET_WAS_SESSION_CONVERSATION);
export const setPlayingVoiceId = createAction(TYPES.SET_PLAYING_VOICE_ID);
export const setReviewModalVisible = createAction(TYPES.SET_REVIEW_MODAL_VISIBLE);

const startTemplateById = (chatId: Astrologist['astrologer_id'], templateId: TEMPLATE_IDS, useDelay = false, hiddenMessage = '') => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const params: { [key: string]: string } = {};

    if (templateId === TEMPLATE_IDS.ASTROEVENT) {
      const transit = getAstroeventMessage(state);
      params.transit = transit;
    }

    await useTemplate(chatId, templateId, params, useDelay, hiddenMessage);
  };
};

const trackSentMessageEvent = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { startChatPlace, currentChatId, currentAstrologerId },
      },
      profile: {
        profileData: { id },
      },
    } = getState();

    let place: START_CHAT_PLACE = START_CHAT_PLACE.TYPING;

    if (startChatPlace) {
      place = startChatPlace;

      dispatch(setStartChatPlaceAction(null));
    }

    Analytics.trackEvent('Astrologist', 'Chat_Message_Sent', {
      place,
      chatId: currentChatId,
      astrologerId: currentAstrologerId,
      userId: id,
    });
  };
};

export const startChatWithAstrologist = (
  chatId: Astrologist['astrologer_id'],
  message: HistoryItem,
  needResponse?: number,
  templateId?: TEMPLATE_IDS,
  place?: ASTROLOGERS_OPEN_PLACE,
  pushNotification?: string,
) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        notifications: { isPressed: isNotificationPressed, notification },
        modals: {
          astroeventCounters: { resetCount },
        },
      },
      profile: {
        profileData: { id, device_id },
      },
      remoteConfig: {
        remoteConfigParams: { advisorsPid, astrologistBackendConfig, enableVoiceMessages },
      },
    } = getState();

    dispatch(setTimePaused(false));
    dispatch(resetAstrologistNotifications());
    dispatch(setQuestionAction(''));

    let startChatMessage = '';
    let metadata = {
      notificationCategory: '',
      er: !!astrologistBackendConfig?.er,
      palmReadingEnabled: !!astrologistBackendConfig?.palmReadingEnabled,
      sps: astrologistBackendConfig?.symbolsPerSecond || 5,
      pid: advisorsPid,
      mtod: astrologistBackendConfig?.maxTimeOfDelay || 30,
      place: !place ? 'other' : place,
      sendPayments: !!astrologistBackendConfig?.sendPayments,
      isVoiceEnabled: !!enableVoiceMessages,
    };

    const isFromPushNotification = !!pushNotification;
    if (isFromPushNotification) {
      startChatMessage = pushNotification;
      metadata = {
        ...metadata,
        notificationCategory: ADVISOR_NOTIFICATIONS.GENERAL,
      };
    }

    let hiddenMessage;
    if (templateId === TEMPLATE_IDS.PALM_READING) {
      const [palmReadingStartMessage, palmReadingMetadata] = getPalmReadingMetadata(getState(), chatId);
      hiddenMessage = palmReadingMetadata.hiddenMessage;
      dispatch(setHiddenMessage(hiddenMessage));
      if (palmReadingStartMessage) {
        startChatMessage = palmReadingStartMessage;
        metadata = {
          ...metadata,
          ...palmReadingMetadata,
        };

        dispatch(setAdvisorPalmReadingData(null));
      }
    }

    if (!_.isEmpty(notification) && isNotificationPressed) {
      startChatMessage = notification.message;
      metadata = {
        ...metadata,
        notificationCategory: notification.category,
      };
    }

    dispatch(setSuggestsVisible(!message?.message?.length));

    const platform = Platform.select<ChatPlatforms>({
      ios: 'ios',
      android: 'android',
      web: isStandalone() ? 'pwa' : 'rnw',
    });

    const response = await startChat({
      id: chatId,
      message: startChatMessage,
      needResponse,
      platform: platform ? platform : 'undetected',
      metadata: metadata,
      webProject: process.env.REACT_APP_PROJECT_ID || 'undetected',
      version: DeviceProps.getAppVersion(),
    });

    dispatch(setCurrentChatId(response?.chat_id));

    if (templateId) {
      const isDelayed = !!startChatMessage?.length;
      dispatch(startTemplateById(chatId, templateId, isDelayed, hiddenMessage));
    }

    Analytics.trackEvent('Astrologist', 'Chat_Open', {
      astrologerId: chatId,
      userId: id,
      idfm: device_id,
      chatId: response?.chat_id,
      fromNotification: isNotificationPressed,
      isFromPushNotification,
      place: metadata.place,
      notificationCategory: metadata.notificationCategory,
    });

    if (isNotificationPressed) {
      dispatch(setNotification(null));
      dispatch(setNotificationPressed(false));
    }

    await dispatch(getHistoryMessages(chatId));

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;
    dispatch(setLastConnectionSessionNumber(sessionNumber));

    const hasChatResetShow = getState().remoteConfig.remoteConfigParams?.astroeventModalConfig?.hasChatResetShow;

    dispatch(
      setAstroeventCounters({
        resetCount: resetCount <= hasChatResetShow ? resetCount + 1 : resetCount,
        withConnections: 0,
        withoutConnections: 0,
      }),
    );

    const isTimeUp = getState().astrologers.time.isTimeUp;
    if (isTimeUp) {
      dispatch(showAutoRefillModal());
      return false;
    }

    const isChatHaveUnreadMessages = !!getState().astrologers.chat.chats[chatId]?.messages?.find(mess => mess?.is_viewed_by_user);

    if (isChatHaveUnreadMessages) {
      await dispatch(setMessagesRead(chatId));
    }

    if (message.message?.length) {
      dispatch(addNewMessage(chatId, [message]));

      setTimeout(() => {
        dispatch(setLastMessageRead(chatId));
      }, 1000);
    }

    dispatch(addPendingChat(chatId));
  };
};

export const sendPalmToAdvisor = (chatId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const palmPhoto = state.astrologers.chat.palmPhoto;
    const rightHandAdvisorData = selectRightHandData(state);

    const hiddenMessage = rightHandAdvisorData?.astrologersMessage;
    const message: HistoryItem = {
      id: Math.random().toString(16),
      type: 'question',
      message: '',
      created_at: moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss'),
      is_viewed: 1,
      is_answered: 1,
      animated: false,
      is_viewed_by_user: 1,
      needAnswer: 0,
      metadata: {
        hiddenMessage,
        type: 'palmScanData',
        url: palmPhoto,
      },
    };
    await useTemplate(chatId, TEMPLATE_IDS.PALM_READING_IN_CHAT, {}, true, hiddenMessage);
    await dispatch(addNewMessage(chatId, [message]));
    dispatch(setPalmPhoto(''));
  };
};

export const addNewMessage = (chatId: Astrologist['astrologer_id'], newMessages: HistoryItem[]) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats, hiddenMessage },
        time: { seconds },
      },
      remoteConfig: {
        remoteConfigParams: {
          astrologistConfig: { animated },
          sendAdvisorTime,
        },
      },
    } = getState();

    if (!Array.isArray(newMessages)) {
      newMessages = [];
    }

    handleEventsForSpecificMessages(newMessages);

    const chatMessages = chats[chatId]?.messages || [];
    let messages = [...newMessages, ...chatMessages];
    const lastChatMessageCreationDate = chatMessages[0]?.created_at;
    const lastNewMessagesCreationDate = newMessages[newMessages?.length - 1].created_at;

    if (countDaysDiff(lastChatMessageCreationDate, lastNewMessagesCreationDate) !== 0 || !chatMessages?.length) {
      const separator = generateSectionData(lastNewMessagesCreationDate);
      messages = [...newMessages, separator, ...chatMessages];
    }

    if (newMessages.every(mess => mess.type === 'question')) {
      dispatch(trackSentMessageEvent());

      newMessages.forEach(async message => {
        const metadata = { ...message.metadata };

        if (sendAdvisorTime) {
          metadata.currentTime = message.created_at;
          metadata.balanceTime = formatSeconds(seconds);
        }

        addMessage(chatId, message.message, hiddenMessage, metadata);
      });

      dispatch(addPendingChat(chatId));
    }

    if (newMessages.every(mess => mess.type === 'answer')) {
      messages = messages.map((message, index) => {
        return {
          ...message,
          animated: animated && index === 0,
        };
      });
    }

    newMessages.forEach(message => {
      if (message.type === 'answer' && message.metadata?.type === 'palmRequest') {
        Analytics.track('advisor_palm_got_scan_offer');
        dispatch(setTimePaused(true));
        dispatch(setIsPalmReadingOffer(true));
      }
    });

    dispatch(
      setChatMessages({
        chatId,
        messages,
      }),
    );

    dispatch(setWasSessionConversation(true));
  };
};

export const getHistoryMessages = (chatId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { isTyping },
      },
      remoteConfig: { remoteConfigParams },
    } = getState();
    const response = await getHistory(chatId, !!remoteConfigParams?.receiveTarot);

    if (!response) {
      dispatch(setChatMessages({ chatId, messages: [] }));
      return false;
    }

    const messages = response?.length ? transformMessages(response) : [];

    dispatch(setChatMessages({ chatId, messages, isTyping }));

    return true;
  };
};

export const addPendingChat = (astrologerId: Astrologist['astrologer_id']) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { pendingChats },
      },
    } = getState();
    const isCurrentChatInPendingChats = pendingChats.find((id: any) => `${id}` === `${astrologerId}`);

    if (isCurrentChatInPendingChats) {
      return false;
    }

    const newPendingChats = [...pendingChats, astrologerId];

    dispatch(setPendingChats(newPendingChats));

    return true;
  };
};

export const deletePendingChatById = (astrologerId: string) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { pendingChats },
      },
    } = getState();

    const chats = pendingChats.filter((id: string | number) => astrologerId !== id);

    dispatch(setPendingChats(chats));
  };
};

export const deleteChatById = (chatId: string) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    const chatIds = Object.keys(chats);
    const chatIndexForDeletion = chatIds.findIndex((id: string) => chatId === id);

    if (chatIndexForDeletion !== -1) {
      const updatedChats = chatIds.splice(chatIndexForDeletion, 1);
      updatedChats.forEach((id: string) => {
        dispatch(setChatMessages(id, chats[id]));
      });

      return true;
    }

    return false;
  };
};

export const getLastMessages = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    const chatIds = Object.keys(chats);
    const response = await getChats();

    dispatch(setCurrentChats(response));

    if (!chatIds.length && !response.length) {
      return false;
    }

    if (chatIds.length && !response.length) {
      dispatch(resetAllChats());

      return false;
    }

    const responseIds = response.map(({ astrologer_id }) => astrologer_id);
    const chatsForDeletion = chatIds.filter(id => !responseIds?.includes?.(`${id}`));
    chatsForDeletion.forEach(id => {
      dispatch(deleteChatById(id));
    });

    response.forEach((chat: any) => {
      const id = chat.astrologer_id;
      const storedChat = chats[id];

      if (!storedChat) {
        return dispatch(
          setChatMessages({
            chatId: id,
            messages: [chat.lastMessage],
          }),
        );
      }

      const lastStoredMessage = storedChat?.messages?.[0]?.message;

      if (lastStoredMessage && chat.lastMessage.message !== lastStoredMessage) {
        dispatch(
          setChatMessages({
            chatId: id,
            messages: [...storedChat.messages, chat.lastMessage],
          }),
        );
      }
    });
  };
};

export const setLastMessageRead = (chatId: Astrologist['astrologer_id']) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const messages = getState().astrologers.chat.chats[chatId].messages.map(message => {
      if (+message?.is_viewed === 0 && message.type === 'question') {
        message.is_viewed = 1;
      }

      return message;
    });

    dispatch(setChatMessages({ chatId, messages }));
  };
};

export const pingChatById = (chatId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch) => {
    dispatch(addPendingChat(chatId));
    await pingChat(chatId);
  };
};

export const countUnreadMessages = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const chats = getState().astrologers.chat.chats;
    const chatsIds = Object.keys(chats);

    let unreadMessagesCounter = 0;

    if (chatsIds.length) {
      chatsIds.forEach((id: string) => {
        const unreadMessages = chats[id].messages?.filter(message => message?.is_viewed_by_user === 0 && message.type === 'answer') || [];
        unreadMessagesCounter += unreadMessages.length;
      });
    }

    dispatch(setUnreadChatCount(unreadMessagesCounter));
  };
};

export const submitQuestion = (question: Question): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setQuestionAction(question));
      await sendAstrologersQuestion(question.text);
    } catch (error) {
      console.log('[SUBMIT QUESTION ERROR]', error);
    }
  };
};

export const startAstrologersPromo = (
  place: ASTROLOGERS_PROMO_PLACES,
  additionParams: {
    taroCard?: string;
    message: string;
  },
) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const randomAstrologist = state.astrologers.core.astrologist;
    const lastAstrologist = getLastConnectedAstrologer(state);
    const consultant = lastAstrologist ? lastAstrologist : randomAstrologist;

    const sectionName = t2(`ASTROLOGER_PROMO.SECTIONS_NAMES.${place.toUpperCase()}`);
    let question: Question = {
      text: t('ASTROLOGER_PROMO.MESSAGE', { sectionName }),
      metadata: {
        type: 'astrologersPromo',
      },
    };

    if (place === ASTROLOGERS_PROMO_PLACES.TAROT) {
      question = {
        ...question,
        text: `${t('ASTROLOGER_PROMO.MESSAGE_TAROT')} ${additionParams?.taroCard || ''}`,
      };
    }

    dispatch(setStartChatPlaceAction(START_CHAT_PLACE.CROSS_PROMO));

    dispatch(setHiddenMessage(additionParams.message));
    dispatch(submitQuestion(question));

    dispatch(setCompleted());
    navigate(ADVISORS_CONNECTION, {
      id: consultant.astrologer_id,
      place: ASTROLOGERS_OPEN_PLACE.CROSS_PROMO_BANNER,
    });
  };
};

export const setMessagesRead = (chatId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    let messages = chats[chatId]?.messages;

    if (!Array.isArray(messages)) {
      messages = [];
    }

    readMessages(chatId);

    messages?.forEach(message => {
      if (typeof message === 'object') {
        message.is_viewed_by_user = 1;
      }
    });

    dispatch(setChatMessages({ chatId, messages }));
    dispatch(countUnreadMessages());
  };
};

export const navigateAfterEndChat = (place: ASTROLOGERS_OPEN_PLACE) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      astrologers: {
        chat: { wasSessionConversation },
      },
    } = getState();

    const isServicesEnabled = remoteConfigParams?.advisorsServicesConfig?.enabled;
    const isNavigateToServices = remoteConfigParams?.advisorsServicesConfig?.navigateAfterEndChatEnabled;

    if (isServicesEnabled && isNavigateToServices && wasSessionConversation) {
      dispatch(setActiveServicesTab(true));
    }

    if (place === ASTROLOGERS_OPEN_PLACE.WHERE_TO_NAVIGATE) {
      return reset(MAIN);
    }

    if (place === ASTROLOGERS_OPEN_PLACE.CHAT || place === ASTROLOGERS_OPEN_PLACE.READING_CHAT) {
      return navigate(ADVISOR_PAGE);
    }

    if (place === ASTROLOGERS_OPEN_PLACE.ASTROEVENT) {
      return reset(MAIN, { initial: false, screen: ADVISORS });
    }

    if (canGoBack()) {
      back();
    } else {
      reset(MAIN, { initial: false, screen: ADVISORS });
    }
  };
};

export const endChat = (params: {
  id: Astrologist['astrologer_id'];
  secondForCharge: number;
  isReviewAvailable: boolean;
  place: ASTROLOGERS_OPEN_PLACE;
}) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats, currentChatId },
      },
      remoteConfig: {
        remoteConfigParams: {
          astrologistConfig: { enableAstrologistReview: isAstrologistReviewEnabled },
        },
      },
    } = getState();

    const { id, secondForCharge, isReviewAvailable, place } = params;

    const isReviewCompleted = chats[id]?.isReviewCompleted || false;

    if (secondForCharge > 0) {
      dispatch(chargeTime(secondForCharge, currentChatId));
    }

    dispatch(setHiddenMessage(''));
    dispatch(setTimePaused(true));
    dispatch(setTimerTooltipVisibility(false));
    dispatch(resetAstrologistNotifications());

    const handleSkip = () => {
      const activeModal = getState().modals.activeModal;
      if (!activeModal && isAstrologistReviewEnabled && !isReviewCompleted && isReviewAvailable) {
        dispatch(setReviewModalVisible(true));
      } else {
        dispatch(navigateAfterEndChat(place));
        dispatch(setCurrentChatId(0));
      }
    };

    const handleSuccess = () => {
      dispatch(setTimePaused(false));
    };

    dispatch(
      astrologersMonetizationHandler({
        trigger: ASTROLOGERS_TRIGGERS_TYPES.END_CHAT,
        onSkip: handleSkip,
        onSuccess: handleSuccess,
      }),
    );
  };
};

export const updateChatInteractionStatus = (typing: boolean, recording: boolean) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { isTyping, isRecording },
      },
    } = getState();

    if (isRecording !== recording || (typing && recording)) {
      dispatch(setIsTyping(false));
      return dispatch(setIsRecording(recording));
    }

    if (isTyping !== typing) {
      dispatch(setIsRecording(false));
      return dispatch(setIsTyping(typing));
    }

    return false;
  };
};
