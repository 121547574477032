import React, { FC } from 'react';
import { TextStyle, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import * as COLORS from 'constants/colors';
import Text from 'components/text';

type Props = {
  text: string;
  style?: StyleProp<ViewStyle>;
  styleText?: StyleProp<TextStyle>;
};

const Title: FC<Props> = ({ text, style, styleText }) => (
  <View style={[styles.container, style]}>
    <Text style={[styles.title, styleText]} font="Philosopher">
      {text}
    </Text>
  </View>
);

export default Title;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    color: COLORS.BEIGE_2,
    fontWeight: '600',
    fontSize: fs(26),
    lineHeight: fs(29),
    textAlign: 'center',
    width: 'auto',
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 0,
  },
});
