export const DEFAULT_LNG = 'en';
export const DEFAULT_NS = 'translation';
export const STORAGE_LNG_KEY = 'APP_LOCALE';

export const LANGUAGES = {
  ar: {
    en: 'Arabic',
    native: 'العربية',
  },
  de: {
    en: 'German',
    native: 'Deutsch',
  },
  en: {
    en: 'English',
    native: 'English',
  },
  es: {
    en: 'Spanish',
    native: 'Español',
  },
  fr: {
    en: 'French',
    native: 'Français',
  },
  ja: {
    en: 'Japanese',
    native: '日本語',
  },
  pt: {
    en: 'Portuguese',
    native: 'Português',
  },
};
